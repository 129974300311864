<template>
  <section>
    <template v-if="logined">
      <issue-center ref="issueCenter" />
    </template>
    <template v-else>
      <land-banner
        :items="banItems"
        :height="classify.height"
        :points="classify.points"
        :cycle="classify.cycle"
        :interval="classify.interval"
        :hide-delimiters="wdHideDelimiters"
        :show-arrows="wdShowArrows"
        :button="btnJoin"
        @action="onJoin"
        @click="onClick"
      />

      <!-- <intro-banner /> -->
      <intro-images />
      <intro-features />

      <guide-media />
      <guide-recommend />
      <home-recommends />

      <guide-activity />
      <home-activities />

      <guide-join />
    </template>

    <section-foot v-if="!logined" />
    <dialog-covers ref="dlgCover" />
  </section>
</template>

<script>
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  import mixHomeBanner from '@/pages/mixins/home/mix.home.banner'

  export default {
    metaInfo () {
      return {
        title: this.title
      }
    },
    components: {
      // IntroBanner: () => import('@/pages/sections/home/IntroBanner.vue'),
      IntroFeatures: () => import('@/pages/sections/home/IntroFeatures.vue'),
      IntroImages: () => import('@/pages/sections/home/IntroImages.vue'),
      HomeRecommends: () => import('@/pages/sections/home/HomeRecommends.vue'),
      HomeActivities: () => import('@/pages/sections/home/HomeActivities.vue'),
      GuideRecommend: () => import('@/pages/sections/home/GuideRecommend.vue'),
      GuideActivity: () => import('@/pages/sections/home/GuideActivity.vue'),
      GuideJoin: () => import('@/pages/sections/home/GuideJoin.vue'),
      GuideMedia: () => import('@/pages/sections/home/GuideMedia.vue'),
      IssueCenter: () => import('@/pages/sections/home/IssueCenter.vue'),
      SectionFoot: () => import('@/pages/sections/Foot.vue'),
      DialogCovers: () => import('@/pages/sections/comm/DialogCovers.vue'),
    },
    mixins: [
      mixHomeBanner
    ],
    data () {
      return {
        logined: false,
        title: '首页'
      }
    },
    created () {
      app.on(app.event.USER_COVER_CHANGE, this.changeCover)
      app.on(app.event.USER_COVER_CHOOSE, this.chooseCover)
      app.on(app.event.HOME_CHECK_LOGIN, this.checkLogined)
      this.initBannerParams({})
      this.loadBanners()
    },
    destroyed () {
      app.off(app.event.USER_COVER_CHANGE)
      app.off(app.event.USER_COVER_CHOOSE)
      app.off(app.event.HOME_CHECK_LOGIN)
    },
    mounted () {
      this.checkLogined()
    },
    methods: {
      changeCover (ev) {
        const frm = this.$refs['issueCenter']
        if (frm) {
          frm.resetCover(ev)
        }
      },
      chooseCover (ev) {
        const frm = this.$refs['dlgCover']
        if (frm) {
          frm.show()
        }
      },
      checkLogined () {
        const me = this
        const callback = function (res) {
          if (res.status) {
            me.title = '关注'
            me.logined = true
          } else {
            me.title = '首页'
            me.logined = false
          }
        }

        user.login.isLogined(callback)
      }
    }
  }
</script>
