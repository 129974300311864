
import api from '@/api/co.api'
import mixBannerBase from '@/pages/mixins/mix.banner.base'

export default {
  mixins: [mixBannerBase],
  data () {
    return {
      btnJoin: {
        text: '加入我们'
      }
    }
  },
  created () {
    // this.classify.height = 360
    // Object.assign(this.classify.points, {
    //   xs: 200,
    //   sm: 300,
    //   md: 360,
    //   lg: 360,
    //   xl: 360
    // })
  },
  methods: {
    onJoin (ev) {
      const params = {
        mode: 'phone'
      }
      api.page.navigate(this, 'login', params)
    },
    onClick (ev) {
      const item = ev.item || {}
      const link = item.link || ''
      if (link) {
        window.open(link, '_blank')
      }
    },
  }
}
